<template>
    <LiefengContent>
        <template v-slot:title>{{ $route.query.userName + $route.query.mobile }} - 积分详情</template>
        <template v-slot:contentArea>
            <div class="leftMenu">
                <LiefengTable
                    :talbeColumns="tableColumns"
                    :tableData="menuList"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    :hidePage="true"
                ></LiefengTable>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    data() {
        return {
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            menuList: [],
            tableColumns: [
                {
                    title: "任务名称",
                    width: 250,
                    key: "taskName",
                    align: "center",
                },
                {
                    title: "积分",
                    minWidth: 200,
                    key: "pointsCount",
                    align: "center",
                },
                {
                    title: "所属类型",
                    minWidth: 200,
                    key: "taskType",
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.taskType && params.row.taskType != "" ? (params.row.taskType == "1" ? "获取" : params.row.taskType == "2" ? "扣除" : "") : "")
                    },
                },
                {
                    title: "操作账号",
                    minWidth: 200,
                    key: "operatorMobile",
                    align: "center",
                },
                {
                    title: "备注",
                    minWidth: 200,
                    key: "remark",
                    align: "center",
                },
                {
                    title: "来源",
                    key: "source",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.source && params.row.source != "" ? (params.row.source == "1" ? "线上" : params.row.source == "2" ? "线下" : "") : "")
                    },
                },
                {
                    title: "操作时间",
                    key: "gmtCreate",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", params.row.gmtCreate && params.row.gmtCreate != "" ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "") 
                    },
                },
                
            ],
        }
    },
    methods: {
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.page = obj.page
            this.getList()
        },
        getList() {
            this.loading = true
            this.$get("/gateway/sy-points/api/sypointes/user/listPointsUserEventDetail", {
                userId: this.$route.query.userId,
                page: this.page,
                pageSize: this.pageSize,
                orgCode: window.sessionStorage.getItem("orgCode"),
                poolCode: "P710019",
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.menuList = res.dataList.filter(item => item.pointsCount != 0)
                    }
                    this.$Message.destroy()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
    },
    async created() {
        this.getList()
    },
}
</script>

<style lang='less'>
/deep/.ivu-form-item-content {
    display: flex !important;
}
.ivu-form-item-label {
    display: flex !important;
}
</style>
